import React from "react";
import { useScroll, useTransform, motion } from "framer-motion";
import bg1 from "../assets/bg1.jpg";
import bg2 from "../assets/bg2.jpg";
import landingbefore from "../assets/landingbefore.jpg";
import landingafter from "../assets/landingafter.jpg";
import beforelanding from "../assets/beforelanding.jpg";
import afterlanding from "../assets/afterlanding.jpg";
import ImageComparison from "react-image-comparison";
import { FaHouse } from "react-icons/fa6";

const Home = () => {
  const { scrollY } = useScroll();
  const y1 = useTransform(scrollY, [0, 1000], [0, 0], { clamp: false });

  return (
    <div className="text-center jost">
      <motion.div
        className="relative h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${bg1})`, y: y1 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#2A2B2A] bg-opacity-50 flex flex-col justify-center items-center sm:items-start sm:pl-14 sm:pr-10 sm:py-20">
          <h1 className="text-[#e7a034] text-4xl md:text-6xl font-bold text-center sm:text-left mr-2 sm:pr-3 pb-2">
            CCcreations
          </h1>
          <h3 className="text-[#e7a034] text-xl md:text-2xl pr-2 mb-4 text-center sm:text-left">
            We're <span className="italic">the</span> ground up solution from
            conception to completion for your dream project.
          </h3>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4 text-center sm:text-left"
          >
            <a
              href="tel:+12067422047"
              className="bg-[#2A2B2A] text-[#e7a034] px-4 py-2 rounded font-bold flex items-center space-x-1 text-lg md:text-xl"
            >
              <span>📞</span>
              <span>(206) 742 2047</span>
            </a>
          </motion.div>
        </div>
      </motion.div>

      <div className="relative h-auto sm:py-16 bg-gray-100">
        <h1 className="text-[#e7a034] text-4xl font-bold text-center pt-4 sm:pt-0">
          {" "}
          We Deliver High-End Results
        </h1>
        <div className="flex flex-col sm:flex-row justify-center items-center w-full space-y-6 sm:space-y-0 sm:space-x-6 p-6 sm:p-20">
          <div className="flex flex-col items-center w-full sm:w-1/2">
            <ImageComparison
              leftImage={landingbefore}
              rightImage={landingafter}
            />
            <div className="flex flex-row items-center mt-4 space-x-2">
              <FaHouse className="text-[#2A2B2A]"></FaHouse>
              <span className="text-lg text-[#2A2B2A]">
                Ferndale Lakehouse Before & After
              </span>
            </div>
          </div>

          <div className="flex flex-col items-center w-full sm:w-1/2">
            <ImageComparison
              leftImage={beforelanding}
              rightImage={afterlanding}
            />
            <div className="flex flex-row items-center mt-4 space-x-2">
              <FaHouse className="text-[#2A2B2A]"></FaHouse>
              <span className="text-lg text-[#2A2B2A]">
                West Seattle Garage Before & After
              </span>
            </div>
          </div>
        </div>
        <motion.div
          whileHover={{
            scale: 1.1,
            translateZ: 50,
            perspective: 1000,
            boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
            transition: { duration: 0.2 },
          }}
          className="inline-block mb-4 text-center text-md sm:text-left"
        >
          <a
            href="/gallery"
            className="bg-[#2A2B2A] text-[#e7a034] px-4 py-2 rounded font-bold flex items-center space-x-1 text-md md:text-xl"
          >
            <FaHouse />
            <span>View Other Work</span>
          </a>
        </motion.div>
      </div>

      <div className="relative h-auto sm:py-16 bg-gray-100">
        <div className="flex flex-col-reverse sm:flex-row justify-center items-start sm:space-x-8 px-6 sm:px-20">
          <div className="relative flex flex-col sm:w-1/2 mt-8 sm:mt-0 space-y-4 order-2 sm:order-1">
            <div className="absolute top-0 left-0 w-full h-[2px] bg-[#2A2B2A] sm:mt-2"></div>
            <div className="relative pt-6 sm:pt-4 pl-4 sm:pl-0">
              <h1 className="text-[#e7a034] text-4xl font-bold text-left pb-6 py-8">
                Why Choose CCcreations?
              </h1>
              <h2 className="text-[#2A2B2A] text-2xl font-bold text-left pb-6 py-8">
                Our Guarantees & Experience:
              </h2>
              <div className="text-left space-y-2 text-[#2A2B2A] text-xl pb-6">
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  • Ground-Up Solution from Conception to Construction.
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  • Licensed.
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  • High End Results.
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.6 }}
                >
                  • Over 20 years of experience.
                </motion.div>
              </div>
              <p className="text-[#e7a034] text-xl mt-4 pb-5 sm:pb-0 text-left">
                <span className="text-[#2A2B2A] underline underline-offset-2 font-bold">
                  About Us:
                </span>{" "}
                CCcreations Offers Construction Solutions, Kitchen & Bath
                Remodels, Home Additions, & Foundation/Structural Repair. We also
                offer Architectural, Design & Engineering Services. We're A
                Ground-Up Solution From Conception to Completion for the Pacific
                NW.
              </p>
            </div>
          </div>
          <div className="relative flex flex-col w-full sm:w-1/2 space-y-4 sm:space-y-0 sm:space-x-4 order-1 sm:order-2">
            <motion.div
              className="relative w-full"
              whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <img
                src={bg2}
                alt="Image 1"
                className="w-full h-auto object-cover p-2"
                style={{ zIndex: 1 }}
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
