import React from 'react';
import { FaEnvelope, FaFacebook } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="bg-[#e7a034] text-[#2A2B2A] py-4 jost">
            <div className="container mx-auto px-4">
                <div className="flex flex-col sm:flex-row justify-between items-center">
                    <div className="text-center sm:text-left mb-4 sm:mb-0">
                        <p>&copy; 2024 CCcreations</p>
                        <p>Phone: (206) 742-2047</p>
                    </div>
                    <div className="flex space-x-4">
                        <a href="mailto:cccreations206@gmail.com" className="hover:text-gray-200">
                            <FaEnvelope size={24} />
                        </a>
                        <a href='https://www.facebook.com/CCcreationsPNW' target='_blank' rel='noreferrer' className="hover:text-gray-200">
                            <FaFacebook size={24} />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
