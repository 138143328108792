import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import bg1 from "../assets/bg1.jpg";
import bg2 from "../assets/bg2.jpg";
import bg3 from "../assets/bg3.jpg";
import landingbefore from "../assets/landingbefore.jpg";
import landingafter from "../assets/landingafter.jpg";
import beforelanding from "../assets/beforelanding.jpg";
import afterlanding from "../assets/afterlanding.jpg";
import ImageComparison from "react-image-comparison";
import { FaHouse } from "react-icons/fa6";
import img1 from "../assets/img1.jpg";
import img2 from "../assets/img2.jpg";
import img3 from "../assets/img3.jpg";
import img4 from "../assets/img4.jpg";
import img5 from "../assets/img5.jpg";
import img6 from "../assets/img6.jpg";
import img7 from "../assets/img7.jpg";
import img8 from "../assets/img8.jpg";

const bgimages = [bg1, bg2, bg3];
const galleryImages = [
  bg1,
  bg2,
  bg3,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
];
const projects = [
  {
    title: "Ferndale Lakehouse Renovation",
    description:
      "A complete modern transformation with a new dock & fresh set of paint.",
    images: [landingbefore, landingafter,],
  },
  {
    title: "West Seattle Garage Conversion",
    description:
      "We painted and remodeled this garage to create a contemporary look.",
    images: [beforelanding, afterlanding, img6, img7],
  },
  {
    title: "Deck Construction",
    description:
      "We built a new deck for a client who needed a trusted builder! Here is the entire construction process.",
    images: [img1, img2, img3],
  }
];

const Gallery = () => {
  const [bgImage, setBgImage] = useState(bgimages[0]);
  const [bgIndex, setBgIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndices, setCurrentImageIndices] = useState(
    Array(projects.length).fill(0)
  );

  const nextImage = (projectIndex) => {
    setCurrentImageIndices((prevIndices) =>
      prevIndices.map((index, idx) =>
        idx === projectIndex
          ? (index + 1) % projects[projectIndex].images.length
          : index
      )
    );
  };

  const prevImage = (projectIndex) => {
    setCurrentImageIndices((prevIndices) =>
      prevIndices.map((index, idx) =>
        idx === projectIndex
          ? index === 0
            ? projects[projectIndex].images.length - 1
            : index - 1
          : index
      )
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % bgimages.length;
        setBgImage(bgimages[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const openImage = (index) => {
    setSelectedImage(index);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-col min-h-screen jost">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#2A2B2A] bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-[#e7a034] text-4xl font-bold mb-4 px-6">
            Past Work
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="tel:+12067422047"
              className="bg-[#2A2B2A] text-[#e7a034] px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>Call us at (206) 742-2047 to Book</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8 flex-1">
        <div className="relative h-auto sm:py-1">
          <div className="flex flex-col sm:flex-row justify-center items-center w-full space-y-6 sm:space-y-0 sm:space-x-6 p-6 sm:p-20">
            <div className="flex flex-col items-center w-full sm:w-1/2">
              <ImageComparison
                leftImage={landingbefore}
                rightImage={landingafter}
              />
              <div className="flex flex-row items-center mt-4 space-x-2">
                <FaHouse className="text-[#2A2B2A]"></FaHouse>
                <span className="text-lg text-[#2A2B2A]">
                  Ferndale Lakehouse Before & After
                </span>
              </div>
            </div>

            <div className="flex flex-col items-center w-full sm:w-1/2">
              <ImageComparison
                leftImage={beforelanding}
                rightImage={afterlanding}
              />
              <div className="flex flex-row items-center mt-4 space-x-2">
                <FaHouse className="text-[#2A2B2A]"></FaHouse>
                <span className="text-lg text-[#2A2B2A]">
                  West Seattle Garage Before & After
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto px-4 py-8 flex-1">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            {projects.map((project, projectIndex) => (
              <div key={projectIndex} className="mb-12">
                <h2 className="text-2xl font-bold text-center mb-4">
                  {project.title}
                </h2>
                <p className="text-center text-lg mb-4">
                  {project.description}
                </p>
                <div className="relative w-full h-96 overflow-hidden">
                  <motion.img
                    src={project.images[currentImageIndices[projectIndex]]}
                    alt={`Project ${projectIndex} - Image ${currentImageIndices[projectIndex]}`}
                    className="w-full h-full object-cover"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  />
                  <button
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2"
                    onClick={() => prevImage(projectIndex)}
                  >
                    Prev
                  </button>
                  <button
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2"
                    onClick={() => nextImage(projectIndex)}
                  >
                    Next
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {galleryImages.map((image, index) => (
            <motion.div
              key={index}
              className="border"
              whileHover={{
                scale: 1.1,
                translateZ: 50,
                perspective: 1000,
                boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
                transition: { duration: 0.2 },
              }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              onClick={() => openImage(index)}
            >
              <img
                src={image}
                alt={`Gallery ${index}`}
                className="w-full h-96 object-cover"
              />
            </motion.div>
          ))}
        </div>
      </div>

      {selectedImage !== null && (
        <motion.div
          className="fixed inset-0 bg-[#2A2B2A] bg-opacity-75 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          onClick={closeImage}
        >
          <motion.img
            src={galleryImages[selectedImage]}
            alt={`Gallery ${selectedImage}`}
            className="max-w-full max-h-full px-4 sm:px-3"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
          />
        </motion.div>
      )}
    </div>
  );
};

export default Gallery;
