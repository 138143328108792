import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { FaArrowRight } from 'react-icons/fa'; 
import bg1 from "../assets/bg1.jpg";
import bg2 from "../assets/bg2.jpg";
import bg3 from "../assets/bg3.jpg";
import kitchen from "../assets/kitchen.jpg";
import bathroom from "../assets/bathroom.jpg";
import painting from "../assets/painting.jpg";
import sound from "../assets/sound.jpg";
import decks from "../assets/decks.jpg";
import addition from "../assets/addition.jpg";
import foundation from "../assets/foundation.jpg";

const Services = () => {
  const images = [bg2, bg3];
  const [bgImage, setBgImage] = useState(images[0]);
  const [bgIndex, setBgIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % images.length;
        setBgImage(images[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className="text-center jost">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#2A2B2A] bg-opacity-50 flex items-center justify-center flex-col">
          <motion.h1
            className="text-[#e7a034] text-4xl font-bold mb-4"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            Our Services
          </motion.h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="tel:+12067422047"
              className="bg-[#2A2B2A] text-[#e7a034] px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>Call us at (206) 742-2047 to Book</span>
            </a>
          </motion.div>
        </div>
      </motion.div>

      <motion.div
        className="bg-[#2A2B2A] py-10 px-4 text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        <motion.h2
          className="text-[#e7a034] text-3xl font-bold mb-4"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          We Deliver from Conception to Completion
        </motion.h2>
        <div className="flex flex-col md:flex-row justify-around items-center text-white">
          <motion.div
            className="mb-4 md:mb-0 flex flex-col items-center"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="bg-[#e7a034] p-4 rounded-full text-2xl mb-2">1</div>
            <h3 className="text-xl font-semibold mb-1">Conceptualization</h3>
          </motion.div>
          <FaArrowRight className="text-[#e7a034] text-3xl mx-4 md:inline-block hidden" /> 
          <motion.div
            className="mb-4 md:mb-0 flex flex-col items-center"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="bg-[#e7a034] p-4 rounded-full text-2xl mb-2">2</div>
            <h3 className="text-xl font-semibold mb-1">Design</h3>
          </motion.div>
          <FaArrowRight className="text-[#e7a034] text-3xl mx-4 md:inline-block hidden" /> 
          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="bg-[#e7a034] p-4 rounded-full text-2xl mb-2">3</div>
            <h3 className="text-xl font-semibold mb-1">Construction</h3>
          </motion.div>
        </div>
      </motion.div>

      <motion.div
        className="max-w-4xl mx-auto text-e7a034 px-4 pt-4 pb-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-4 text-white">
          <ServiceCard title="General Construction" backgroundImage={bg1} path="/services/general-construction"/>
          <ServiceCard title="Home Additions" backgroundImage={addition} />
          <ServiceCard title="Design & Engineering" backgroundImage={bg2} />
          <ServiceCard title="Kitchen Remodels" backgroundImage={kitchen} />
          <ServiceCard title="Bathroom Remodels" backgroundImage={bathroom} />
          <ServiceCard title="Decks & Outdoor Habitats" backgroundImage={decks} path="/services/decks"/>
          <ServiceCard title="Foundation Repair" backgroundImage={foundation} />
          <ServiceCard title="Sound & Acoustic Design" backgroundImage={sound} />
          <ServiceCard title="Painting" backgroundImage={painting} />
        </div>
      </motion.div>
    </div>
  );
};

const ServiceCard = React.forwardRef(({ title, backgroundImage,path }, ref) => {
  const { ref: inViewRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const cardStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <Link to={path} className="block" ref={inViewRef}>
      <motion.div
        className="bg-e7a034 bg-opacity-25 rounded-md p-4 flex flex-col items-center justify-center text-center hover:bg-opacity-50 transition duration-300 h-40 w-full"
        style={cardStyle}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5 }}
        whileHover={{
          scale: 1.1,
          translateZ: 50,
          perspective: 1000,
          boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
          transition: { duration: 0.01 },
        }}
      >
        <h2 className="text-3xl font-semibold mb-2">{title}</h2>
      </motion.div>
    </Link>
  );
});

export default Services;
