import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import gc1 from "../../assets/gc/gc1.jpg";
import gc2 from "../../assets/gc/gc2.jpg";
import gc3 from "../../assets/gc/gc3.JPG";
import gc4 from "../../assets/gc/gc4.JPG";
import gc5 from "../../assets/gc/gc5.JPG";
import gc6 from "../../assets/gc/gc6.JPG";
import gc7 from "../../assets/gc/gc7.JPG";
import gc8 from "../../assets/gc/gc8.JPG";
import gc9 from "../../assets/gc/gc9.JPG";
import gc10 from "../../assets/gc/gc10.JPG";
import gc11 from "../../assets/gc/gc11.jpg";
const projects = [
    {
        title: "Post Frame Work",
        description: "We built a Shop using Post Frame work which had a 2 Bedroom Accesory Dwelling Unit in Ferndale, WA",
        images: [gc1, gc2, gc4, gc6, gc8, gc10],
    },
];

const bgimages = [gc1, gc3];

const galleryImages = [gc1, gc2, gc3, gc4, gc5, gc6, gc7, gc8, gc9, gc10, gc11];

const GeneralConstruction = () => {
  const [bgImage, setBgImage] = useState(bgimages[0]);
  const [bgIndex, setBgIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndices, setCurrentImageIndices] = useState(
    Array(projects.length).fill(0)
  );

  const nextImage = (projectIndex) => {
    setCurrentImageIndices((prevIndices) =>
      prevIndices.map((index, idx) =>
        idx === projectIndex
          ? (index + 1) % projects[projectIndex].images.length
          : index
      )
    );
  };

  const prevImage = (projectIndex) => {
    setCurrentImageIndices((prevIndices) =>
      prevIndices.map((index, idx) =>
        idx === projectIndex
          ? index === 0
            ? projects[projectIndex].images.length - 1
            : index - 1
          : index
      )
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % bgimages.length;
        setBgImage(bgimages[newIndex]);
        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const openImage = (index) => {
    setSelectedImage(index);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-col min-h-screen jost">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-[#2A2B2A] bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-[#e7a034] text-4xl font-bold mb-4 px-6">
            General Construction
          </h1>
          <motion.div
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            className="inline-block mb-4"
          >
            <a
              href="tel:+12067422047"
              className="bg-[#2A2B2A] text-[#e7a034] px-4 py-2 rounded font-bold"
            >
              <span>📞</span>
              <span>Call us at (206) 742-2047 to Book</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8 flex-1">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-8">
            {projects.map((project, projectIndex) => (
              <div key={projectIndex} className="mb-12 ">
                <h2 className="text-2xl font-bold text-center mb-4">
                  {project.title}
                </h2>
                <p className="text-center text-lg mb-4">
                  {project.description}
                </p>
                <div className="relative w-1/2 h-96 overflow-hidden mx-auto">
                  <motion.img
                    src={project.images[currentImageIndices[projectIndex]]}
                    alt={`Project ${projectIndex} - Image ${currentImageIndices[projectIndex]}`}
                    className="w-full h-full object-cover"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  />
                  <button
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2"
                    onClick={() => prevImage(projectIndex)}
                  >
                    Prev
                  </button>
                  <button
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2"
                    onClick={() => nextImage(projectIndex)}
                  >
                    Next
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4">
        {galleryImages.map((image, index) => (
          <motion.div
            key={index}
            className="border"
            whileHover={{
              scale: 1.1,
              translateZ: 50,
              perspective: 1000,
              boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            onClick={() => openImage(index)}
          >
            <img
              src={image}
              alt={`Gallery ${index}`}
              className="w-full h-96 object-cover"
            />
          </motion.div>
        ))}
      </div>

      {selectedImage !== null && (
        <motion.div
          className="fixed inset-0 bg-[#2A2B2A] bg-opacity-75 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          onClick={closeImage}
        >
          <motion.img
            src={galleryImages[selectedImage]}
            alt={`Gallery ${selectedImage}`}
            className="max-w-full max-h-full px-4 sm:px-3"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
          />
        </motion.div>
      )}
    </div>
  );
};

export default GeneralConstruction;
